<template>
  <VideoView
    v-if="selectedMovie"
    :movie="selectedMovie"
    :key="selectedMovie"
    @clear="selectedMovie = null"
  />
  <div class="movies" v-else>
    <div
      class="movie"
      v-for="movie in movies"
      :key="movie.file"
      @click="selectMovie(movie)"
    >
      <p class="title">{{ movie.title }}</p>
      <p class="description">{{ movie.description }}</p>
    </div>
  </div>
</template>

<script>
// import httpApi from "@/httpApi";
import VideoView from "./components/VideoView.vue";

export default {
  name: "App",
  data() {
    return {
      movies: [
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E01.mp4",
          subtitle: "S03E01.vtt",
          description: "S03E01",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E02.mp4",
          subtitle: "S03E02.vtt",
          description: "S03E02",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E03.mp4",
          subtitle: "S03E03.vtt",
          description: "S03E03",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E04.mp4",
          subtitle: "S03E04.vtt",
          description: "S03E04",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E05.mp4",
          subtitle: "S03E05.vtt",
          description: "S03E05",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E06.mp4",
          subtitle: "S03E06.vtt",
          description: "S03E06",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E07.mp4",
          subtitle: "S03E07.vtt",
          description: "S03E07",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E08.mp4",
          subtitle: "S03E08.vtt",
          description: "S03E08",
        },
        {
          title: "Bordertown (Sorjonen)",
          file: "S03E09.mp4",
          subtitle: "S03E09.vtt",
          description: "S03E09",
        },
      ],
      selectedMovie: null,
    };
  },
  methods: {
    selectMovie(movie) {
      this.selectedMovie = movie;
    },
  },
  components: {
    VideoView,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

html, body {
  width: 100%;
  height: 100%;
  background: rgb(69,69,69);
  background: linear-gradient(153deg, rgba(69,69,69,1) 0%, rgba(47,47,47,1) 42%, rgba(69,69,69,1) 100%);
}

.movies p {
  margin: 0;
}

.movie {
  padding: 10px;
  text-align: left;
  width: 200px;
  height: 50px;
  background: #ededed;
  cursor: pointer;
  transition: 0.3s all;
  float: left;
  color: #fff;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #813de0;
}

.movie:hover {
  background-color: #a76df9;
}

.title {
  font-weight: bold;
}

.description {
  font-size: 75%;
  opacity: 0.6;
}

@media only screen and (max-width: 600px) {
  .movie {
    width: 86%;
  }
}
</style>
