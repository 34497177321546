<template>
  <button @click="close">Close</button>
  <div class="hello">
    <p class="title">{{ movie.title }} - {{ movie.description }}</p>
    <video class="videoCls" controls ref="videoRef">
      <source :src="movie.file" type="video/mp4" />
      <track
        label="English"
        kind="subtitles"
        srclang="en"
        :src="movie.subtitle"
        default
      />
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoView",
  props: ["movie"],
  methods: {
    close() {
      this.$emit("clear");
    },
  },
  mounted() {
    this.$refs.videoRef.load();
    this.$refs.videoRef.play();
    // setTimeout(() => {
    //   this.$refs.videoRef.load();
    //   this.$refs.videoRef.play();
    // }, 1000)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  background-color: #813de0;
  border: none;
  cursor: pointer;
  padding: 4px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #fff;
}
button:hover {
  background-color: #a76df9;
}
.videoCls {
  width: 100%;
}
.title {
  color: #fff;
}
</style>
